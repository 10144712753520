/**
 * @file
 * Styles for Checkout
 */

@import "variables";

$tr-hover: darken(#dde3ed, 5%);

// Continue Shopping
#block-block-13 {
  position: relative;

  p {
    margin: 0;
  }

  a {
    margin: 0;
    display: inline-block;
    padding: 1px 5px 2px;
    background: $blue;
    @include border-radius(3px);
    @include transition(
      background 0.2s ease-in-out
    );

    @media only screen and (max-width: 420px) {
      position: initial;
    }

    &:hover {
      background: $blue-dark;
      color: white;
      text-decoration: none;
    }
  }
}

//
// Checkout Cart (/cart)
//

body.page-cart {

  // Cart Form
  #views-form-commerce-cart-form-default {

    // Make links red
    a {
      color: $red-dark;
    }

    th, td {
      vertical-align: middle;
    }

    // Image
    th.views-field-field-images {
      width: 95px;
      @media only screen and (max-width: 650px) {
        display: none;
      }
    }
    td.views-field-field-images {
      width: 95px;
      @media only screen and (max-width: 650px) {
        display: none;
      }
      img {
        margin: 0;
        border: 2px solid white;
        @include transition(
          all 0.2s ease-in-out
        );
      }
    }

    // Quantity
    th.views-field-edit-quantity {
      @media only screen and (max-width: 650px) {
        font-size: 0;
      }
    }
    td.views-field-edit-quantity {
      // Text Field Quantity
      .form-type-textfield {
        float: left;
        margin-right: 5px;
        @media only screen and (max-width: 650px) {
          width: 36px;
          margin-right: 0;
        }
        input {
          @include border-radius(2px);
        }
      }

      // Delete Button
      input.delete-line-item {
        position: relative;
        //@include icons-sprite(close, $offset-x: -9px, $offset-y: -9px);
        background-color: darken($grey-light, 5%);
        background-image: url('/sites/default/themes/thewheel/images/sprites/icons-s8275b01c75.png');
        background-position-x: -9px;
        background-position-y: -9px;
        text-indent: -9999px;
        height: 25px;
        width: 25px;
        top: 1px;
        @include border-radius(3px);
        @include transition(
          background 0.2s ease-in-out
        );

        @media only screen and (max-width: 650px) {
          width: 36px;
          float: left;
          clear: left;
        }

        &:hover {
          background-color: $red-dark;
        }
      }
      @media only screen and (max-width: 650px) {
        input.delete-line-item {
          //@include icons-sprite(close, $offset-x: -4px, $offset-y: -9px);
        }
      }
    }

    // Title
    .views-field-line-item-title {
      a { font-weight: bold; }
    }

    // Form Buttons
    .form-submit {
      @include border-radius(3px);
    }

    // Update Cart Button
    #edit-submit {
      float: left;
      background: $blue;
      @include transition(
        background 0.2s ease-in-out
      );
      &:hover {
        background-color: $blue-dark;
      }
    }

    // Checkout Button
    #edit-checkout {
      font-size: 1.7em;
      padding: 10px;
      margin-right: 0;
      @include transition(
        background 0.2s ease-in-out
      );
      &:hover {
        background: darken($red-dark, 10%);
      }
    }

    // Even Rows
    tbody tr.even {
      // Image
      td.views-field-field-images {
        img {
          border: 2px solid $grey-light;
        }
      }
    }

    // All Rows
    tbody tr {
      @include transition(
        all 0.2s ease-in-out
      );
      &:hover {
        background: lighten($tr-hover, 2%);;
        td.views-field-field-images {
          img {
            border-color: darken($grey-light, 25%);
          }
        }
        td.views-field-edit-quantity {
          // Delete Button
          input.delete-line-item {
            background-color: darken($grey-light, 25%);
            &:hover {
              background-color: $red-dark;
            }
          }
        }
      }
    }

  }

}

//
// Checkout
//

body.page-checkout {

  // Cart Form
  .view-commerce-cart-summary {

    // Make links red
    a {
      color: $red-dark;
    }

    th, td {
      vertical-align: middle;
    }

    // Image
    th.views-field-field-images {
      width: 95px;
      @media only screen and (max-width: 650px) {
        display: none;
      }
    }
    td.views-field-field-images {
      width: 95px;
      @media only screen and (max-width: 650px) {
        display: none;
      }
      img {
        margin: 0;
        border: 2px solid white;
      }
    }

    // Quantity
    th.views-field-edit-quantity {
      @media only screen and (max-width: 650px) {
        font-size: 0;
      }
    }
    td.views-field-edit-quantity {
      // Text Field Quantity
      .form-type-textfield {
        float: left;
        margin-right: 5px;
        @media only screen and (max-width: 650px) {
          width: 36px;
          margin-right: 0;
        }
      }
    }

    // Title
    .views-field-line-item-title {
      a { font-weight: bold; }
    }

    // Even Rows
    tr.even {
      // Image
      td.views-field-field-images {
        img {
          border: 2px solid $grey-light;
        }
      }
    }

    // All Rows
    tbody tr {
      @include transition(
        all 0.2s ease-in-out
      );
      &:hover {
        background: lighten($tr-hover, 2%);;
        td.views-field-field-images {
          img {
            border-color: darken($grey-light, 25%);
          }
        }
        td.views-field-edit-quantity {

          // Delete Button
          input.delete-line-item {
            background-color: darken($grey-light, 25%);
            &:hover {
              background-color: $red-dark;
            }
          }
        }
      }
    }


  }


  fieldset {
    width: 100%;
  }

  // Checkout Buttons
  .checkout-buttons {
    border: none;
    margin: 10px 0;
    padding: 0;

    // Continue to next step button
    .checkout-continue {
      font-size: 1.7em;
      padding: 10px;
      margin-right: 10px;
      position: relative;
      top: 3px;
      @include border-radius(3px);
      @include transition(
        background 0.2s ease-in-out
      );
      &:hover {
        background: darken($red-dark, 10%);
      }

    }
  }

  .shipping-service-wrapper {
    display: inline-block;
    margin: 0 30px;
    vertical-align: top;
    padding-bottom: 20px;

    .shipping-services {
      text-align: left;
    }

    .shipping-service-logo {
      min-height: 158px;
      text-align: center;
      position: relative;
      margin-bottom: 20px;

      a {
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        font-style: italic;
      }
    }

    .usps-logo {
      padding-top: 35px;
    }
    .fedex-logo {
      padding-top: 45px;
    }
    .ups-logo {
      padding-top: 29px;
    }

    span.shipping-dates {
      font-size: 85%;
      margin-left: 1.3em;
    }
  }

  .commerce-pane-tooltip {
    padding: 0 5px;

    img {
      vertical-align: top;
    }
  }
}

#edit-commerce-shipping-shipping-service {
  text-align: center;
}

.form-item-commerce-shipping-shipping-service {
  img {
    margin: 0;
    position: relative;
    top: 5px;
  }

  .description {
    display: none;
  }
}

//
// Payment
//

#edit-commerce-payment-payment-method {
  display: flex;
  align-items: center;

  .form-item {
    display: inline-block;
    margin: 0 20px 0 0;

    .commerce-paypal-ec-icon, .commerce-paypal-bml-icon {
      top: 0;
      margin: 0;
    }

    #edit-commerce-payment-payment-method-paypal-eccommerce-payment-paypal-ec {
      vertical-align: super;
    }

  }
}



//
// Checkout Complete
//

.checkout-completion-message {
  padding: 80px 0 20px;
  text-align: center;
  font-size:140%;
}




//
// Shipping description popups
//

.shipping-descriptions-wrapper {

  opacity: 0;
  display: none;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3001;

  background: rgba(0,0,0, .7);

  cursor: pointer;

  @include transition(opacity .5s);

  > div {

    position: absolute;
    top: 10%;
    //bottom: 10%;
    left: 10%;
    right: 10%;

    max-height: 80%;

    padding: 20px;

    overflow: auto;

    background: white;

    text-align: left;

    .close {
      position: fixed;
      top: 11%;
      right: 11%;

      width: 20px;
      height: 20px;

      font-size: 22px;

      font-weight: bold;
      cursor: pointer;
    }

  }


  &.open {

    opacity: 1;
    display: block;

  }

}



//Card.JS

.jp-card-container {
  display: none;
}
